<template>
  <el-dialog
    :model-value="props.visible"
    title="批量激活"
    width="500px"
    :before-close="cancel"
  >
    <div class="data ws_mr5 ws_pt20 ws_pb20">
      <el-form
        :model="formData"
        :rules="rules"
        ref="formRef"
        label-position="left"
        label-width="110"
      >
        <el-form-item prop="datagramNum" label="回传报文条数">
          <el-select
            v-model="formData.datagramNum"
            class="ws_select"
            placeholder="请选择回传报文条数"
          >
            <el-option
              v-for="item in datagramNumList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <p class="ws_ml12">条/天</p>
        </el-form-item>
        <el-form-item prop="effectivePeriod" label="生效周期">
          <el-select
            v-model="formData.effectivePeriod"
            class="ws_select"
            placeholder="请选择生效周期"
          >
            <el-option
              v-for="item in effectivePeriodList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <p class="ws_ml12">月</p>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button :loading="loading" type="primary" @click="handleAddDevice">
          激活
        </el-button>
        <el-button plain @click="cancel">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref, defineEmits, defineProps } from 'vue'
import { handeleBatchActive } from '@/api'
import { CODE_ENUM } from '@/common/enum'
import { ElMessage } from 'element-plus'

const $emit = defineEmits(['update:visible', 'success'])
const props = defineProps(['visible', 'data'])

const formData = ref({
  datagramNum: '',
  effectivePeriod: '',
})

const cancel = () => {
  $emit('update:visible', false)
  formRef.value.resetFields()
}

const rules = {
  datagramNum: [{ required: true, message: '请选择' }],
  effectivePeriod: [{ required: true, message: '请选择' }],
}

const loading = ref(false)
const formRef = ref()

const handleAddDevice = () => {
  formRef.value.validate(async (valid) => {
    if (valid) {
      loading.value = true
      const { datagramNum, effectivePeriod } = formData.value
      const deviceIds = (props.data || []).map((_) => _.deviceId)
      const res = await handeleBatchActive({
        datagramNum,
        effectivePeriod,
        deviceIds,
      }).finally(() => {
        loading.value = false
      })
      if (res?.code === CODE_ENUM.SUCCESS) {
        ElMessage({
          message: '操作成功',
          type: 'success',
        })
        cancel()
        $emit('success')
      }
    }
  })
}

const datagramNumList = [
  {
    label: '12',
    value: '12',
  },
  {
    label: '24',
    value: '24',
  },
  {
    label: '48',
    value: '48',
  },
]

const effectivePeriodList = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '6',
    value: '6',
  },
  {
    label: '12',
    value: '12',
  },
  {
    label: '24',
    value: '24',
  },
  {
    label: '36',
    value: '36',
  },
]
</script>
<style lang="scss" scoped>
.ws_select {
  width: 280px;
}
</style>
